import React, { useState } from "react"
// import GroupDetailsView from "../../components/media-output/lists/group-details-view/group-details-view"
import SearchGroupContent from "../../components/search/search-group-content/search-group-content"
//import SearchGroupEditContent from "../../components/search-groupedit-content"
import styles from "./index.module.scss"
import SearchLayout from "../../components/layout/search-layout/search-layout"
import ViewGroup from "../../components/media-output/view-group/view-group"
import { useLocation } from "react-router-dom"
import auth from "../../services/auth"



const ViewMyGroups = props => {
  console.log('Search:', props.location)
  

  // Use URLSearchParams to parse the query string
  const searchParams = new URLSearchParams(props.location.state);
  const gid = searchParams.get('gid'); // Assuming your parameter name is 'gid'
  console.log('gid:', gid)


  return (
    <SearchLayout location={props.location} searchPath="search">
      {/* <SearchGroupContent gid={gid}></SearchGroupContent> */}
      <ViewGroup gid={gid}></ViewGroup>
    </SearchLayout>
  )
}

export default ViewMyGroups
